import React, { Component } from 'react'
import './App.scss'

class App extends Component {
  render () {
    return (
      <div className="container crt">
        <div className="vignette"></div>
        <h1 className="glitch" data-text="MRPH">MRPH</h1>
        <p className="glitch" data-text="Studio">Studio</p>
        <p className='city'>Buenos Aires, <br/> Argentina.</p>
      </div>
    )
  }
}

export default App
